import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import FilterBarComponent from "../../components/filterBar/filterBarComponent";
import Modal from "../../components/modal/Modal";
import "./shoppablead.scss";
import commHeroImg from "../../assets/img/shoppable_banner.png";

const ShoppableAd = () => {
  const [commGallery, setcommGallery] = useState([]);
  const [loading, setLoading] = useState(false);
  let [color] = useState("#1E9A4B");
  const [toggleModal, setToggleModal] = useState(false);
  let [pli, setPli] = useState([]);

  const filterDataCommGallery = (resData) => {
    let keys = resData.values[0];
    let newData = resData.values.slice(1, resData.values.length);

    let formatted = [],
      data = newData,
      cols = keys,
      l = cols.length;
    for (var i = 0; i < data.length; i++) {
      var d = data[i],
        o = {};
      for (var j = 0; j < l; j++) o[cols[j]] = d[j];
      formatted.push(o);
    }
    setcommGallery(formatted);
    sortingDisplay("display");
    setLoading(true);
  };

  useEffect(() => {
    const getAPI = function () {
      fetch(
        "https://sheets.googleapis.com/v4/spreadsheets/1kJl_ioUAK1umhl9oCHF8Oo7u698QdngllHuwerOFpIo/values/commerce?alt=json&key=" +
          process.env.REACT_APP_API_KEY
      )
        .then((res) => res.json())
        .then((res) => {
          filterDataCommGallery(res);
        })
        .catch((error) => {
          console.error("Error:", error);
          setTimeout(() => {
            getAPI();
          }, 1000);
        });
    };
    getAPI();
  }, []);

  function handleOnClick(e) {
    const sortBtns = document.querySelectorAll(".sorting-btn");
    let getType = e.currentTarget.getAttribute("data-sort");
    sortBtns.forEach((ele) => {
      ele.classList.remove("btn-active");
    });
    e.currentTarget.classList.add("btn-active");
    sortingDisplay(getType);
  }

  function sortingDisplay(type) {
    const items = document.querySelectorAll(".item-col");
    items.forEach((ele) => {
      const dataType = ele.getAttribute("data-type");
      ele.style.display = "none";
      if (dataType === type) {
        ele.style.display = "block";
      }
    });
  }

  function handleToggleModal(e) {
    const getLinkType = e.currentTarget.getAttribute("data-type");
    if (getLinkType === "instream") {
      setToggleModal(true);
      setPli(e.currentTarget.getAttribute("data-demo"));
    } else {
      window.open(e.currentTarget.getAttribute("data-demo"));
    }
  }

  return (
    <div>
      <FilterBarComponent parent="commerce" />
      <div className="content-bottom no-filter commerce-ad">
        {toggleModal && (
          <Modal toggleModal={setToggleModal} data={pli} dataType="instream" />
        )}
        {!loading ? (
          <ClipLoader color={color} size={180}></ClipLoader>
        ) : (
          <div>
            <div className="title col-12 col-md-12">
              <div className="heading row">
                <div className="col-12 col-md-4 d-flex justify-content-md-end justify-content-sm-center">
                  <img
                    className="hero-img"
                    src={commHeroImg}
                    alt="commerce"
                  ></img>
                </div>

                <div className="text col-12 col-md-6">
                  <h2>Shoppable Ad</h2>
                  <p>
                    Let's make the shopping experience truly everywhere! Excite
                    consumers' shopping impulses with a wide array of choices,
                    and craft personalized shopping recommendation packages for
                    diverse audience segments.
                  </p>
                  <div className="comm-filter row g-0">
                    <div className="filter-title col-md-2 col-12">Format</div>
                    <div className="col-md-10 col-12 btn-group">
                      <button
                        className="btn btn-outline-white btn-active sorting-btn"
                        data-sort="display"
                        onClick={handleOnClick}
                      >
                        Display
                      </button>
                      <button
                        className="btn btn-outline-white sorting-btn"
                        data-sort="instream"
                        onClick={handleOnClick}
                      >
                        Instream
                      </button>
                      <button
                        className="btn btn-outline-white sorting-btn"
                        data-sort="social"
                        onClick={handleOnClick}
                      >
                        Social
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-layout row commerce-gallery">
              {commGallery.map((v, k) =>
                v.type === "display" || v.type === "social" ? (
                  <div
                    className="item-col display-column col-12 col-md-3"
                    key={k}
                    data-type={v.type}
                  >
                    <div className="thumbs text-center">
                      <video muted autoPlay playsInline loop>
                        <source src={v.prevvid} type="video/mp4" />
                      </video>
                    </div>
                    <div className="desc-box ps-4 pe-4">
                      <h5 className="card-title">{v.tempname}</h5>
                      <div className="mb-4">{v.desc}</div>
                      <div>
                        <button
                          className="btn btn-primary demo-btn"
                          onClick={handleToggleModal}
                          data-type={v.type}
                          data-demo={v.demolink}
                        >
                          View and interact with Ad
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="col-12 col-md-6 mb-5 item-col instream-col"
                    key={k}
                    data-type={v.type}
                  >
                    <div className="row">
                      <div className="col-12 col-md-6 thumbs text-center">
                        <video muted autoPlay playsInline loop>
                          <source src={v.prevvid} type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-12 col-md-6 desc-box">
                        <h5 className="card-title">{v.tempname}</h5>
                        <div>{v.desc}</div>
                        <div>
                          <button
                            className="btn btn-primary demo-btn"
                            onClick={handleToggleModal}
                            data-type={v.type}
                            data-demo={v.demolink}
                          >
                            View and interact with Ad
                          </button>
                        </div>
                      </div>
                      <div className="d-none d-sm-block col-md-2"></div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShoppableAd;
