import logo from "../../img/performics_logo_white.svg";
import "./sidebar.scss";
import { Link } from "react-router-dom";

function CustomLink({ to, children, ...props }) {
  let currentPath = window.location.href;

  let param = currentPath.substring(currentPath.lastIndexOf("#") + 1);

  if (param === "" || param === "/") {
    param = "/gallery";
  }
  return (
    <li className={param === to ? "active" : ""} onClick={handleSelected}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}

function handleSelected(e) {
  //reset
  var menus = document.querySelectorAll("li");
  for (let i = 0; i < menus.length; i++) {
    menus[i].classList.remove("active");
  }
  e.target.parentNode.classList.add("active");

  handleDeviceSidebar();
}

function handleDeviceSidebar() {
  const sidebar = document.querySelector("#sidebar");
  const rightContent = document.querySelector("main .content-right");
  const isMobile = document.querySelector("main");

  if (isMobile.classList.contains("mobile-view")) {
    if (sidebar.classList.contains("expand")) {
      sidebar.classList.remove("expand");
      rightContent.classList.remove("expand");
      sidebar.classList.add("collapse");
    }
  }
}

const Siderbar = (props) => {
  return (
    <nav id="sidebar" className="sidebar">
      <div className="sidebar-content js-simplebar">
        <div className="navbar mb-4">
          <CustomLink to="/">
            <img src={logo} className="logo" width="120" alt="logo" />
          </CustomLink>
        </div>

        <div className="home-title d-flex text-white mb-4">
          <div className="align-self-center">
            <div className="mb-2">Experience Lab</div>
          </div>
        </div>

        <ul id="nav-group">
          <CustomLink to="/gallery" urlparam={props}>
            互動廣告 <br />
            Intreactive Display Ad
          </CustomLink>
          <CustomLink to="/adfilter">
            廣告特效 <br />
            PM AD Filter
          </CustomLink>
          <CustomLink to="/instream">
            互動影音廣告 <br />
            Interactive In-stream Video
          </CustomLink>
          <CustomLink to="/shoppablead">Shoppable Ad</CustomLink>
          {/* <CustomLink to="/pdooh">
            pDooh創意廣告
            <br />
            pDooh Creative Solution Solution
          </CustomLink> */}
        </ul>
        <footer>
          <span className="fw-light">© Performics Taiwan | Privacy Policy</span>
        </footer>
      </div>
    </nav>
  );
};

export default Siderbar;
