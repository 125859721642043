import FilterBarComponent from "../components/filterBar/filterBarComponent";
export default function ComingSoon() {
  return (
    <div>
      <FilterBarComponent parent="adfilter" />
      <div className="content-bottom coming-soon">
        <svg
          className="coming-lion"
          id="lion"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1708.6 2205.2"
        >
          <polygon
            className="st0"
            points="683.8,2190.6 733.1,2056.6 722.8,1898.3 844.6,1739.8 969.6,1899.3 962.3,2066.6 1011.8,2188.6 
	1029.1,2053.8 1064.1,1921.8 990.8,1582.1 896.8,1511.3 789.8,1511.3 695.1,1579.8 628.3,1919.8 665.1,2054.1 "
          />
          <polygon
            className="st0"
            points="844.7,1739.6 930.8,1850.4 846.2,1954.4 760.5,1849.4 "
          />
          <polygon
            className="st0"
            points="890.7,1511.3 844,1652.1 794,1511.3 "
          />
          <polygon
            className="st0"
            points="628.3,1919.8 595.3,2020.9 453.3,1809.4 625,1361.1 636.3,1419.4 762.8,1507.4 927,1508.2 
	1052.8,1420.7 1064.1,1362.2 1240.8,1814.6 1098.5,2023.7 1064.1,1921.8 990.8,1582.1 896.8,1511.3 789.8,1511.3 695.1,1579.8 "
          />
          <polygon
            className="st0"
            points="625.8,1360.3 758.5,1371.6 844.5,1349.9 930.5,1372.4 1064.1,1362.2 1052.8,1420.7 927,1508.2 
	762.8,1507.4 636.3,1419.4 "
          />
          <polygon
            className="st0"
            points="788.9,1364.3 809.7,1396.7 878.8,1396.7 900.9,1364.7 844.5,1349.9 "
          />
          <polygon
            className="st0"
            points="453.3,1809.4 446.4,1832.7 444.8,1367.6 534.1,1254.7 553.4,1279.4 555.4,1301.3 618.5,1359.1 
	625,1361.1 "
          />
          <polygon
            className="st0"
            points="1240.8,1814.6 1250.3,1824 1246.3,1367.1 1152.7,1252.8 1135.4,1277 1135.4,1304.1 1072.7,1361.1 
	1064.1,1362.2 "
          />
          <polygon
            className="st0"
            points="1250.3,1824 1252.6,1926.1 1427.8,1640.3 1366.6,1359.1 1182.3,1210.6 1152.8,1251.3 1155.9,1256.1 
	1246.3,1367.1 "
          />
          <polygon
            className="st0"
            points="1427.8,1640.3 1451.5,1719.7 1504.3,1557.6 1279,1076.1 1182.3,1210.6 1366.6,1359.1 "
          />
          <polygon
            className="st0"
            points="1504.3,1557.6 1546.8,1617.7 1492.6,1239.7 1306,1039.7 1279,1076.1 "
          />
          <polygon
            className="st0"
            points="1306,1039.7 1492.6,1239.7 1594.5,1339.6 1580.8,1139.1 1394.3,1001.1 1288.1,793.1 1273.3,898.1 "
          />
          <polygon
            className="st0"
            points="1552.4,1118.1 1554.5,1023.2 1439.6,837.2 1296.5,736.7 1288.1,793.1 1394.3,1001.1 "
          />
          <polygon
            className="st0"
            points="1554.5,1023.2 1663.8,1199.3 1608.8,717.3 1343.6,615.8 1247.8,689.3 1296.5,736.7 1439.6,837.2 "
          />
          <polygon
            className="st0"
            points="1608.8,717.3 1537.6,609.8 1399.6,598.6 1292.3,556.3 1134.8,583.3 1247.8,689.3 1343.6,615.8 "
          />
          <polygon
            className="st0"
            points="1134.8,583.3 1113.1,560.8 1076.3,560.8 1265.8,424.6 1380.6,277.3 1504.8,334.3 1627.1,613.6 
	1537.6,609.8 1399.6,598.6 1292.3,556.3 "
          />
          <polygon
            className="st0"
            points="446.4,1832.7 446.4,1923.1 269.6,1639.1 326.8,1361.1 505.3,1213.3 534.1,1254.7 444.8,1367.6 "
          />
          <polygon
            className="st0"
            points="269.6,1639.1 250.8,1725.7 196.8,1560.7 408.3,1080.6 505.3,1213.3 326.8,1361.1 "
          />
          <polygon
            className="st0"
            points="196.8,1560.7 159.6,1622.3 205.6,1249.3 385.6,1046.8 408.3,1080.6 "
          />
          <polygon
            className="st0"
            points="205.6,1249.3 112.6,1351.8 118.3,1154.3 298.1,1012.1 396.1,802.6 415.1,907.8 385.6,1046.8 "
          />
          <polygon
            className="st0"
            points="149.1,1130 144.3,1040.1 252.8,849.1 387.1,748.3 396.1,802.6 298.1,1012.1 "
          />
          <polygon
            className="st0"
            points="144.3,1040.1 44.8,1213.3 84.5,736.1 341.5,628.1 437.5,698.7 387.1,748.3 252.8,849.1 "
          />
          <polygon
            className="st0"
            points="84.5,736.1 152.3,625.3 283.6,613.8 391.3,567.3 541.1,590.1 437.5,698.7 341.5,628.1 "
          />
          <polygon
            className="st0"
            points="152.3,625.3 62.3,634.3 177.8,352.1 298.1,293.8 413.3,436.1 602.8,567.3 564.6,567.3 541.1,590.1 
	391.3,567.3 283.6,613.8 "
          />
          <polygon
            className="st0"
            points="298.1,293.8 256.1,242.3 364.6,263.6 418.6,308.1 548.1,349.3 673.6,567.3 764.3,628.1 814.1,769.6 
	734.1,849.6 619.3,783.8 495.3,796.3 387.1,748.3 564.6,567.3 602.8,567.3 413.3,436.1 "
          />
          <polygon
            className="st0"
            points="553.3,578.8 600.8,761.6 475.7,657.9 "
          />
          <polygon
            className="st0"
            points="415.1,907.8 535.1,1110.3 643.8,974.6 625.8,939.1 652.1,912.8 543.3,842.8 495.3,796.3 387.1,748.3 
	"
          />
          <polygon
            className="st0"
            points="652.1,912.8 703,904.7 677.5,858.9 545.2,844 "
          />
          <polygon
            className="st0"
            points="643.8,974.6 714.2,1006.2 755.5,931.4 734.1,849.6 619.3,783.8 495.3,796.3 545.2,844 677.5,858.9 
	703,904.7 652.1,912.8 625.8,939.1 "
          />
          <polygon
            className="st0"
            points="553.4,1279.4 548.8,1182.6 535.1,1110.3 415.1,907.8 385.6,1046.8 "
          />
          <polygon
            className="st0"
            points="551.3,1235.6 648.3,1122.9 714.2,1006.2 643.8,974.6 535.1,1110.3 548.8,1182.6 "
          />
          <polygon
            className="st0"
            points="844.5,1349.9 826.3,1275.2 743.7,1246.9 698.2,1175.6 670.2,1084.1 648.3,1122.9 551.3,1235.6 
	555.4,1301.3 618.5,1359.1 757.7,1372.4 "
          />
          <polygon
            className="st0"
            points="844.5,1349.9 930.5,1372.4 1064.1,1362.2 1072.7,1361.1 1135.4,1304.1 1135.4,1235.6 1039.3,1122.6 
	1015.6,1084.1 990,1175.1 943.1,1247.6 861.1,1275.3 "
          />
          <polygon
            className="st0"
            points="698.2,1175.6 825.3,1201.6 844.5,1228.3 862.3,1201.9 990,1175.1 943.1,1247.6 861.1,1275.3 
	844.5,1349.9 826.3,1275.2 743.7,1246.9 "
          />
          <polygon
            className="st0"
            points="698.2,1175.6 841.8,1116.1 990,1175.1 862.3,1201.9 844.5,1228.3 825.3,1201.6 "
          />
          <polygon
            className="st0"
            points="364.6,263.6 270.8,185.7 242.6,34.2 612.3,197.4 700.2,369.6 839.7,457.1 976.5,365.9 1064.1,186.1 
	1436.8,13.6 1409.6,167.6 1258.6,295.8 1128.3,340.1 1032.6,511.6 839.7,601.6 643.1,514.4 548.1,349.3 418.6,308.1 "
          />
          <polygon
            id="test"
            className="st0"
            points="698.2,1175.6 670.2,1084.1 755.5,931.4 734.1,849.6 814.1,769.6 764.3,628.1 673.6,567.3 643.1,514.4 
	839.7,601.6 1032.6,511.6 1003.8,563.1 914.6,626.8 867.1,769.1 950.1,849.6 927.3,929.3 1015.6,1084.1 990,1175.1 841.8,1116.1 "
          />
          <polygon
            className="st0"
            points="612.3,197.4 602.8,114.4 530.5,79.1 612.3,89.7 753.4,166.1 839.7,431.1 920.5,163.1 1060.1,79.1 
	1143.5,64.4 1072.5,105.1 1064.1,186.1 976.5,365.9 839.7,457.1 700.2,369.6 "
          />
          <polygon
            className="st0"
            points="771.8,222.6 837,268.6 903.4,219.9 839.7,431.1 "
          />
          <polygon
            className="st0"
            points="1380.6,277.3 1422.3,224.1 1315.5,247.4 1258.6,295.8 1128.3,340.1 1003.8,563.1 914.6,626.8 
	867.1,769.1 950.1,849.6 1063.1,779.6 1186.1,790.6 1296.5,736.7 1113.1,560.8 1076.3,560.8 1265.8,424.6 "
          />
          <polygon
            className="st0"
            points="1205.8,650.6 1080.6,756.8 1124.6,572.8 "
          />
          <polygon
            className="st0"
            points="1135.4,1277 1135.4,1235.6 1151.1,1105.4 1273.3,898.1 1306,1039.7 "
          />
          <polygon
            className="st0"
            points="1135.4,1235.6 1039.3,1122.6 1015.6,1084.1 970,1004.2 1040.1,972.4 1151.1,1105.4 "
          />
          <polygon
            className="st0"
            points="1040.1,972.4 1057.3,934.1 1032.6,909.3 1139.6,837.8 1186.1,790.6 1296.5,736.7 1273.3,898.1 
	1151.1,1105.4 "
          />
          <polygon
            className="st0"
            points="1032.6,909.3 982.8,901.6 1007.7,855.9 1139.6,837.8 1186.1,790.6 1063.1,779.6 950.1,849.6 
	927.3,929.3 970,1004.2 1040.1,972.4 1057.3,934.1 "
          />
          <polygon
            className="st0"
            points="1032.6,909.3 982.8,901.6 1007.7,855.9 1139.6,837.8 "
          />
        </svg>

        <h1>COMING SOON</h1>
      </div>
    </div>
  );
}
